<template>
  <div>
    <ToggleNavigation :items="items" :type="type"></ToggleNavigation>
    <v-alert dense prominent shaped type="success" v-model="showAlertExcel"
      >Excel generado con exito</v-alert
    >
    <v-snackbar v-model="snackbar" top="top" color="primary">
      Este pescador ya tienen una solicitud completada y a la espera de ser
      resuelta
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-card class="mx-auto">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        v-model="selected"
        :headers="filteredHeaders"
        :items="filtrado.data"
        :search="searchFishermen"
        sort-by="name"
        class="elevation-1"
        :server-items-length="filtrado.total"
        v-on:update:options="onPaginate"
        @input="verificarPescadores"
        :show-select="singleSelect"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            
            <v-toolbar-title
              >Pescadores
              {{
                type == "ind"
                  ? "Industriales"
                  : type == "art"
                  ? "Artesanales"
                  : type == "dep"
                  ? "Deportivos"
                  : ""
              }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-dialog
              v-model="dialogSee"
              scrollable
              persistent
              max-width="800px"
            >
              <v-card>
                <v-card-title
                  class="headline indigo darken-4 white--text"
                  primary-title
                  >Pescador {{ editedItem.fishin_card_number }}
                  {{ editedItem.name }} {{ editedItem.lastname }}
                </v-card-title>
                <v-card-text style="height: 400px">
                  <v-container>
                    <v-row>
                      <v-flex xs12>
                        <v-subheader class="title font-weight-bold"
                          >Descripción Actividad Pesquera</v-subheader
                        >
                      </v-flex>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.fishin_card_number"
                          label="Número de Carné de Pesca"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.expedition_date"
                          label="Fecha de Expedición"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.expiration_date"
                          label="Fecha de Expiración"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type != 'ind'">
                        <v-text-field
                          :value="
                            editedItem.puerto_desembarque != null
                              ? editedItem.puerto_desembarque.nombre
                              : editedItem.landing_zone_state
                          "
                          label="Puerto de Desembarque"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                          :value="editedItem.frequent_fishing_area"
                          label="Zona Frecuente de Pesca"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                          :value="
                            editedItem.tipo_barco_artesanal != null
                              ? editedItem.tipo_barco_artesanal.nombre
                              : ''
                          "
                          label="Tipo de Barco Artesanal"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type !== 'dep'">
                        <v-text-field
                          :value="
                            editedItem.type_fishery != undefined
                              ? editedItem.type_fishery.length > 0
                                ? editedItem.type_fishery.map(
                                    (x) => x.pivot.description
                                  )
                                : ''
                              : ''
                          "
                          label="Tipo de Pesquería"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type != 'ind'">
                        <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Artes de Pesca</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="(item, index) in editedItem.fishing_arts"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="((item || {}).pivot || {}).description || (item || {}).nombre || item"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
                      </v-col>
                      <v-flex xs12>
                        <v-subheader class="title font-weight-bold"
                          >Identificación del Pescador</v-subheader
                        >
                      </v-flex>

                      <v-col cols="4">
                        <v-avatar size="110">
                          <img
                            :src="path + editedItem.fisherman_photo_file"
                            alt="John"
                          />
                        </v-avatar>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          label="Nombre Completo"
                          :value="editedItem.name + ' ' + editedItem.lastname"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.id_type"
                          label="Tipo Identificación"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.identification_number"
                          label="Número de Identificación"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type != 'dep'">
                        <v-text-field
                          :value="editedItem.no_occre"
                          label="Número de OCCRE"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.occre"
                          label="Clasificación certificación OCCRE"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="
                            editedItem.nacionalidad != null
                              ? editedItem.nacionalidad.nombre
                              : ''
                          "
                          label="Nacionalidad"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.email"
                          label="E-mail"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.address"
                          label="Dirección"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          :value="editedItem.phone"
                          label="Teléfono"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type != 'ind'">
                        <v-text-field
                          readonly
                          :value="
                            editedItem.organization != null
                              ? editedItem.organization.abbr_name
                              : ''
                          "
                          label="Organización Cooperativa"
                        ></v-text-field>
                      </v-col>

                      <v-flex xs12 v-if="type != 'dep'">
                        <v-subheader class="title font-weight-bold"
                          >Información Socioeconómica</v-subheader
                        >
                      </v-flex>

                      <v-col cols="4" v-if="type != 'dep'">
                        <v-text-field
                          :value="editedItem.age"
                          label="Edad"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type != 'dep'">
                        <v-text-field
                          :value="
                            editedItem.sexo != null
                              ? editedItem.sexo.nombre
                              : ''
                          "
                          label="Sexo"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                          :value="
                            editedItem.estado_civil != null
                              ? editedItem.estado_civil.nombre
                              : ''
                          "
                          label="Estado Civil"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-checkbox
                          readonly
                          v-model="editedItem.read_and_write"
                          label="Lee y Escribe"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                             :value="(editedItem.nivel_educativo || {}).nombre || editedItem.education_level "
                            label="Nivel Educativo"
                            readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-checkbox
                          readonly
                          v-model="editedItem.own_house"
                          label="Posee Casa Propia"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-checkbox
                          readonly
                          v-model="editedItem.health_service"
                          label="Posee Servicio de Salud"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                          :value="
                            editedItem.tipo_servicio_salud != null
                              ? editedItem.tipo_servicio_salud.nombre
                              : ''
                          "
                          label="Tipo de Servicio de Salud"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                          :value="
                            editedItem.tiempo_de_pesca != null
                              ? editedItem.tiempo_de_pesca.nombre
                              : ''
                          "
                          label="Tiempo de Pesca"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                          :value="
                            editedItem.tiempo_en_actividad != null
                              ? editedItem.tiempo_en_actividad.nombre
                              : ''
                          "
                          label="Dedicación"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type == 'art'">
                        <v-text-field
                          :value="
                            editedItem.horario_de_pesca != null
                              ? editedItem.horario_de_pesca.nombre
                              : ''
                          "
                          label="Horario de Pesca"
                          readonly
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" v-if="type != 'dep'">
                        <v-checkbox
                          readonly
                          v-model="editedItem.health_service"
                          label="Cumple con los requisitos"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                      </v-col>

                      <v-flex xs12>
                        <v-subheader class="title font-weight-bold"
                          >Solicitudes</v-subheader
                        >
                      </v-flex>

                      <v-col>
                        <v-expansion-panels>
                          <v-expansion-panel
                            :key="item.id"
                            v-for="item in solicitudes"
                          >
                            <v-expansion-panel-header v-slot="{}">
                              <v-row no-gutters>
                                <v-col cols="4">
                                  {{
                                    item.type_of_request == "UNO"
                                      ? "Nuevo"
                                      : item.type_of_request == "DOS"
                                      ? "Prorroga"
                                      : item.type_of_request == "TRES"
                                      ? "Duplicado y/o Modificación"
                                      : item.type_of_request == "CUATRO"
                                      ? "Cancelacion"
                                      : item.type_of_request == "CINCO"
                                      ? "Reactivacion"
                                      : ""
                                  }}
                                  {{
                                    item.estado == 1
                                      ? "EN PROCESO"
                                      : item.estado == 2
                                      ? "COMPLETADO"
                                      : item.estado == 3
                                      ? "APROBADO"
                                      : item.estado == 4
                                      ? "RECHAZADO"
                                      : item.estado == 5
                                      ? "CANCELADO"
                                      : ""
                                  }}
                                </v-col>
                                <v-col cols="2">
                                  <v-icon>card_membership</v-icon>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row no-gutters>
                                <v-col cols="3">
                                  Número de radicado
                                  <br />
                                  <div v-if="item.filed">{{ item.filed }}</div>
                                  <div v-else>N/A</div>
                                </v-col>
                                <v-col cols="3">
                                  Fecha de radicado
                                  <br />
                                  <div v-if="item.filing_date">
                                    {{ item.filing_date }}
                                  </div>
                                  <div v-else>N/A</div>
                                </v-col>

                                <v-col cols="3">
                                  Lugar
                                  <br />
                                  <div v-if="item.location">
                                    {{
                                      item.location == "UNO"
                                        ? "SAN ANDRES"
                                        : item.location == "DOS"
                                        ? "PROVIDENCIA"
                                        : ""
                                    }}
                                  </div>
                                  <div v-else>N/A</div>
                                </v-col>
                                <v-col cols="3">
                                  Fecha de Expedición
                                  <br />
                                  <div v-if="item.expedition_date">
                                    {{ item.expedition_date }}
                                  </div>
                                  <div v-else>N/A</div>
                                </v-col>

                                <v-col cols="6" class="mt-4">
                                  Fecha de Expiración
                                  <br />
                                  <div v-if="item.expiration_date">
                                    {{ item.expiration_date }}
                                  </div>
                                  <div v-else>N/A</div>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>

                      <template
                        v-if="editedItem.boats && editedItem.boats.length > 0"
                      >
                        <v-flex xs12>
                          <v-subheader class="title font-weight-bold"
                            >Embarcaciones</v-subheader
                          >
                        </v-flex>

                        <v-col cols="12" v-if="type == 'art'">
                          <v-list>
                            <v-list-item
                              v-for="item in editedItem.boats"
                              :key="item.id"
                            >
                              <v-list-item-content>
                                <v-expansion-panels>
                                  <v-expansion-panel>
                                    <v-expansion-panel-header v-slot="{ on }">
                                      <v-row no-gutters v-on="on">
                                        <v-col cols="4"
                                          >{{ item.boat_name }} |
                                          {{ item.registration_number }}</v-col
                                        >
                                        <v-col cols="2">
                                          <v-icon>fa-ship</v-icon>
                                        </v-col>
                                      </v-row>
                                    </v-expansion-panel-header>
                                    
                                    <v-expansion-panel-content>
                                      <v-row no-gutters>
                                        <v-col cols="3">
                                          Número de registro
                                          <br />
                                          <div v-if="item.registration_number">
                                            {{ item.registration_number }}
                                          </div>
                                          <div v-else>N/A</div>
                                        </v-col>

                                        <v-col cols="3">
                                          Puerto de registro
                                          <br />

                                          <div v-if="item.registration_port">
                                            {{ item.registration_port }}
                                          </div>
                                          <div v-else>N/A</div>
                                        </v-col>

                                        <v-col cols="3">
                                          Tipo de barco
                                          <br />
                                          <div v-if="item.boat_type">
                                            {{ item.boat_type.nombre }}
                                          </div>
                                          <div v-else>N/A</div>
                                        </v-col>

                                        <v-col cols="3">
                                          Estado del pabellón
                                          <br />

                                          <div
                                            v-if="item.current_state_pavilion"
                                          >
                                            {{
                                              item.current_state_pavilion
                                            }}
                                          </div>
                                          <div v-else>N/A</div>
                                        </v-col>

                                        <v-col cols="3">
                                          Propietario
                                          <br />

                                          <div
                                            v-if="item.owner_name"
                                          >
                                            {{ item.owner_name }}
                                          </div>
                                          <div v-else>N/A</div>
                                        </v-col>

                                        <v-col cols="3">
                                          Tipo certificación
                                          <br />
                                          <div
                                            v-if="item.owner_acredit"
                                          >
                                            {{ item.owner_acredit }}
                                          </div>
                                          <div v-else>N/A</div>
                                        </v-col>
                                      </v-row>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogSee = false"
                    >Cerrar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <template
                  v-if="type == 'ind'
                  ? $can('gestionarPescadorIndustrial')
                  : type == 'art'
                  ? $can('gestionarPescadorArtesanal')
                  : type == 'dep'
                  ? $can('gestionarPescadorDeportivo')
                  : ''"
                >
                  <v-btn
                    color="indigo darken-4 ml-4 white--text"
                    dark
                    small
                    class="mb-2"
                    @click="newFisherman"
                    v-on="on"
                    
                  >
                    <v-icon left small>fa-plus</v-icon>
                    Nuevo Permiso
                  </v-btn>
                  <v-btn
                    color="gray darken-4 ml-4 white--text"
                    small
                    dark
                    class="mb-2"
                    @click="ViewPeticiones"
                  >
                    <v-icon small class="mr-2">assignment</v-icon>
                    Solicitudes
                  </v-btn>
                </template>

                <export-excel
                  :fetch.sync="exportFishermens"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                  :fields="
                    type == 'art'
                      ? fields.json_fields_art
                      : type == 'ind'
                      ? fields.json_fields_ind
                      : type == 'dep'
                      ? fields.json_fields_dep
                      : {}
                  "
                  :worksheet="
                    type == 'art'
                      ? 'Pescadores Artesanales'
                      : type == 'ind'
                      ? 'IPescadores ndustriales'
                      : type == 'dep'
                      ? 'Pescadores Deportivos'
                      : ''
                  "
                  :name="
                    type == 'art'
                      ? 'Pescadores Artesanales'
                      : type == 'ind'
                      ? 'Pescadores Industriales'
                      : type == 'dep'
                      ? 'Pescadores Deportivos'
                      : ''
                  "
                  style="ml-4 margin-bottom:10px"
                >
                  <v-btn
                    color="green darken-4 ml-4 white--text"
                    small
                    dark
                    class="mb-2"
                    ref="export"
                    v-if="type == 'ind'
                      ? $can('exportarExcelIndustriales')
                      : type == 'art'
                      ? $can('exportarExcelArtesanales')
                      : type == 'dep'
                      ? $can('exportarExcelDeportivos')
                      : ''"
                  >
                    <v-icon small class="mr-2">fa fa-file-excel</v-icon>
                    Exportar
                  </v-btn>
                  <!--v-icon>file-excel</v-icon-->
                </export-excel>

                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchFishermen"
                  append-icon="search"
                  label="Busqueda"
                  single-line
                  hide-details
                  v-debounce:200ms="search"
                ></v-text-field>
              </template>
            </v-dialog>
          </v-toolbar>
          <v-menu v-model="showMenu">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ name_filter_muni }}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('muni', '', 'Municipio')"
                >
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('muni', 'UNO', 'San Andrés')"
                >
                  <v-list-item-title>San Andrés</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('muni', 'DOS', 'Providencia')"
                >
                  <v-list-item-title>Providencia</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu v-model="showMenu_v">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ name_filter_vigency }}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('vigency', '', 'Vigencia')"
                >
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('vigency', '1', 'Vigencia (SI)')"
                >
                  <v-list-item-title>Si</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('vigency', '0', 'Vigencia (NO)')"
                >
                  <v-list-item-title>No</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>             
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.name |  uppercase }} {{ item.lastname |  uppercase  }}
        </template>
        <template v-slot:item.expiration_date="{ item }">
          <v-chip
            :color="permisoVigente(item.expiration_date, item.estado)"
            dark
            >{{
              permisoVigente(item.expiration_date, item.estado) == "green"
                ? "Sí"
                : "No"
            }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="exportPDF(item)" v-if="item.type_of_card === 'TRES' && item.estado == 1">
                <v-list-item-title>
                  <v-icon small class="mr-2">assignment</v-icon>
                  Certificado de pesca industrial
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="seeItem(item.id)" v-if="type == 'ind'
                      ? $can('verDetalleDelPescadorIndustrial')
                      : type == 'art'
                      ? $can('verDetalleDelPescadorArtesanal')
                      : type == 'dep'
                      ? $can('verDetalleDelPescadorDeportivo')
                      : ''">
                <v-list-item-title>
                  <v-icon small class="mr-2">remove_red_eye</v-icon>
                  Información
                  
                </v-list-item-title>
              </v-list-item>
              <div v-show="type != 'ind'">
                <v-list-item
                  @click="cardItem(item)"
                  v-if="(permisoVigente(item.expiration_date, item.estado) == 'green') && item.type_of_card !== 'TRES' && 
                        type == 'art'
                        ? $can('verCarnetsArtesanales')
                        : type == 'dep'
                        ? $can('verCarnetsDeportivos')
                        : ''">
                  <v-list-item-title>
                    <v-icon small>account_box</v-icon>
                    Carnet
                  </v-list-item-title>
                </v-list-item>
              </div>
              <v-list-item
                @click="Peticion('p', item)"
                v-if="canManagement && permisoVigente(item.expiration_date, item.estado) == 'green'
                "
              >
                <v-list-item-title>
                  <v-icon class="mr-2" small>mdi-timetable</v-icon>
                  Prorroga
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="Peticion('d', item)"
                v-if="canManagement && permisoVigente(item.expiration_date, item.estado) == 'green'
                "
              >
                <v-list-item-title>
                  <v-icon class="mr-2" small>file_copy</v-icon>
                  Duplicado y/o Modificación
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="Peticion('c', item)"
                v-if="canManagement && permisoVigente(item.expiration_date, item.estado) == 'green'
                "
              >
                <v-list-item-title>
                  <v-icon class="mr-2" small>block</v-icon>
                  Cancelacion
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="Peticion('r', item)"
                v-if="canManagement && permisoVigente(item.expiration_date, item.estado) == 'red'
                "
              >
                <v-list-item-title>
                  <v-icon class="mr-2" small>check</v-icon>
                  Reactivacion
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="permisoVigente(item.expiration_date, item.estado) === 'green' &&  type==='art'"
                @click="toggleDeliveryStatus(item)"
              >
                <v-list-item-title v-if="type==='art'">
                  <v-icon class="mr-2" small>
                    {{ item.estate ? 'mdi-check-circle' : 'mdi-close-circle-outline' }}
                  </v-icon>
                  {{ item.estate ? 'Cambiar a No entregado' : 'Cambiar a Entregado' }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="permisoVigente(item.expiration_date, item.estado) === 'green' &&  type==='art'"
                @click="generatePDF(item)"
              >
                <v-icon left>mdi-file-pdf-box</v-icon>
                Certificado de Pesca Artesanal
              </v-list-item>


            </v-list>
          </v-menu>
        </template>

        <template #item.estate="{ item }">
      <span
        :class="item.estate ? 'text-success' : 'text-error'"
      >
        {{ item.estate ? 'Entregado' : 'No Entregado' }}
      </span>
        </template>



        <!-- FOTO -->
        <template v-slot:item.fisherman_photo_file="{ item }">
          <v-avatar
            style="height: 55px;
              min-width:55px;
              width: 55px;"
            v-if="
              item.fisherman_photo_file &&
              item.fisherman_photo_file.substring(0, 10) === 'fisherman-'
            "
          >
            <img
              :src="path + item.fisherman_photo_file"
              alt="John"
              @click="showModal(item)"
            />
          </v-avatar>
        </template>
          <template v-slot:footer>
            <v-flex style="display: inline-flex; align-items: center;" v-if="type !== 'ind'">
        <v-switch
          v-model="singleSelect"
          @change="reloadFishermen()"
          label="Generar carnets por lotes"
          class="pa-3"
        ></v-switch>
                 <v-btn color="primary" v-if="singleSelect" @click="generateCarnetFisherman">Generar</v-btn>

            </v-flex>
      
      </template>
      </v-data-table>

    </v-card>
    <view-fishing-card v-if="fisherman.fishingCard"></view-fishing-card>
    <v-dialog v-model="modalVisible" max-width="1000px">
      <v-card>
        <v-card-title class="text-h6 text-center">{{fullName}}</v-card-title>
        <v-card-text class="d-flex justify-center">
          <img
            :src="imageUrl"
            alt="Imagen del pescador"
            style="
          max-width: 90%;
          width: 800px;
          height: auto;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        "
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-4" text @click="closeModal">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>

</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getAllRequestByFisherman, apiDomain, updateState } from "./../config.js";
import { navegacion } from "../services/exportNavigation";
import ToggleNavigation from "../components/ToggleNavigation";
import getFishermanFields from "../services/exportFishermens";
import { exportPDF } from "../services/certificadoPDF";
import axios from "axios";
import ViewFishingCard from "./ViewFishingCard.vue";
import { findRequestFishermen, getHeader } from "../config";
import swal from "sweetalert";
import moment from "moment";
const DEFAULT_VALUE = "Desconocido";
import { photoFishermanUrl, firmaUrl } from "./../config.js";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import baseService from "../services/BaseService.js";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
/*import createFile from "jspdf";*/

import {
  imgLogoGobernacion,
  imgDataUp,
  imgData
} from "../assets/base64/imges-carnet";
import Swal from "sweetalert2";


export default {
  props: ["type", "organization"],
  data: () => ({
    shield: require('../assets/media/rpg/escudogobernacion.png'),
    modalVisible: false,
    items: [],
    exportPDF: exportPDF,
    path: `${apiDomain}storage/fishermen/`,
    imageUrl: '',
    fullName: '',
    searchFishermen: "",
    dialogSee: false,
    solicitudes: [],
    fields: {},
    showMenu: false,
    showMenu_v: false,
    singleSelect: false,
    name_filter_vigency: "Vigencia",
    name_filter_muni: "Municipio",
    selected: [],
    zip: new JSZip(),
    headers: [
      { text: "Foto", sortable: false, value: "fisherman_photo_file" },
      { text: "No. de Permiso", sortable: true, value: "fishin_card_number" },
      { text: "Nombre(s)", align: "left", sortable: true, value: "name" },
      {
        text: "Número de Identificación",
        sortable: false,
        value: "identification_number",
      },
      { text: "Municipio", sortable: false, value: "lugar.nombre" },
      { text: "Vigencia", sortable: true, value: "expiration_date" },
      {text: "Estado", sortable: true, value: "estate"},
      { text: "Acciones", value: "action", sortable: false },
    ],
    type: '',

    //VARIABLES FORM
    menu_date_expedition: false,
    menu_date_expiration: false,
    date_expedition: "",
    date_expiration: "",
    item_date_expiration: "",
    dialogPro: false,

    dialog: false,

    snackbar: false,
    filtrado: [],
    editedItem: {},
    dataItem: {},
    company: {},
    barco: {},
    lugar: {},
    tipo_autorizacion: {},
    tipo_solicitud: {},
    artes_autorizadas: [],
    type_of_company: "",
    defaultItem: {},
    department: {},
    nacionalidad: {},
    organizacion: {},
    arte_pesca: {},
    sexo: {},
    tiempo_en_actividad: {},
    puerto_desembarque: {},
    tipo_barco_artesanal: {},
    tipo_pesqueria: {},
    estado_civil: {},
    nivel_educativo: {},
    tipo_servicio_salud: {},
    tiempo_de_pesca: {},
    horario_de_pesca: {},
    excelFishermens: [],
    tipo_permiso: {},
    pageNumber: 0,
    filterLocation: "",
    filterVigency: "",
    showAlertExcel: false,
    pathFisherman: photoFishermanUrl,
    fishermanStatus: "",
    fishingCard: {},
    firmaSecretario: {},
  }),
  components: {
    ViewFishingCard,
    ToggleNavigation
  },
  computed: {
    ...mapGetters([
      "allFishermen",
      "allFishermenArt",
      "allFishermenInd",
      "allFishermenDep",
      "fishermenData",
    ]),
    ...mapState(["fisherman"]),
    fishermanOrg() {
      if (this.fishermanStatus === "INDEPENDENT") {
        return "NOT APPLICABLE";
      } else {
        return this.fishermanOrganization;
      }
    },
    occreType() {
      if (this.fishingCard.occre === "RESIDENTE") {
        return "RESIDENT";
      } else if (this.fishingCard.occre === "RAIZAL") {
        return "RAIZAL";
      } else {
        return "";
      }
    },
    username() {
      return JSON.parse(localStorage.getItem("authUser"));
    },
    canManagement(){
      if (this.type === 'ind') {
        return this.$can('gestionarPescadorIndustrial')
      }
      else if (this.type === 'art') {
        return this.$can('gestionarPescadorArtesanal')
      } else {
         return this.$can('gestionarPescadorDeportivo')
      }
    },
    filteredHeaders() {
      // Filtrar la columna "Estado" cuando type sea "art"
      return this.headers.filter(
        (header) => header.value !== "estate" || this.type === "art"
      );
    },
  },

  watch: {
    type: async function () {
      this.filterFishermen();
    },
  },
  created: async function () {
    await this.onPaginate({ page: 1, itemsPerPage: 10 });
    this.items = navegacion.items_navegation;
    this.fields = await getFishermanFields();
      const url =
          firmaUrl +
          this.$store.state.settings.find((x) => x.nombre == "firma_secretario")
            .valor;
        const data = await this.generarBase64Blob(url);
        this.firmaSecretario = data;
  },

  methods: {
    async toggleDeliveryStatus(item) {
      const newStatus = !item.estate;
      const url = updateState(item.id);

      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: `El estado será cambiado a "${newStatus ? 'Entregado' : 'No entregado'}".`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "<span style='color: #ffffff;'>Sí, cambiar</span>",
        cancelButtonText: "<span style='color: #ffffff;'>Cancelar</span>",
        confirmButtonColor: "#2863a7",
        cancelButtonColor: "#dc3545",

      });

      if (result.isConfirmed) {
        try {
          const response = await axios.put(url, { state: newStatus });
          item.estate = response.data.data.estate;

          await Swal.fire({
            title: "¡Éxito!",
            text: "Estado actualizado correctamente.",
            icon: "success",
            confirmButtonText: "<span style='color: #ffffff;'>Aceptar</span>",
            confirmButtonColor: "#28a745",
            customClass: {
              confirmButton: 'swal-confirm-text-white'
            }
          });
        } catch (error) {
          await Swal.fire({
            title: "Error",
            text: "No se pudo actualizar el estado.",
            icon: "error",
            confirmButtonText: "<span style='color: #ffffff;'>Aceptar</span>",
          });
        }
      } else {
        await Swal.fire({
          title: "Cancelado",
          text: "No se realizó ningún cambio.",
          icon: "info",
          confirmButtonText: "<span style='color: #ffffff;'>Aceptar</span>",
        });
      }
    },
    async generateCarnetFisherman() {
      if(this.selected == 0)
      {
        swal({
          title: "Error!",
          text: "No hay pescadores seleccionados",
          icon: "error",
        });return;
      }
       
      for(const item of this.selected) {
        let fisherman = {
          ...item,
          type_of_card:
            item.type_of_card == "DOS"
              ? item.landing_zone_state
              : item.landing_zone,
        }

        let sendData = {};
        this.fishingCard = fisherman;
        const fishingCard = fisherman;
        sendData.fishingCard = fishingCard;
        sendData.page_back = fishingCard.page_back;
        sendData.fisherman_photo_file = fishingCard.fisherman_photo_file;
        sendData.identification_number = fishingCard.identification_number;
        sendData.name = fishingCard.name;
        sendData.lastName = fishingCard.lastname;
        sendData.fishin_card_number = fishingCard.fishin_card_number;
        sendData.expiration_date = fishingCard.expiration_date
          ? moment(fishingCard.expiration_date).format("YYYY-MM-DD")
          : "";
        sendData.nationality = fishingCard.nationality;
        sendData.activity = fishingCard.activity;
        sendData.landing_zone =
          fishingCard.landing_zone_state ||
          fishingCard.puerto_desembarque?.nombre ||
          DEFAULT_VALUE;
        sendData.expedition_place = fishingCard.expedition_place;
        sendData.expedition_date = fishingCard.expedition_date;
        this.fishermanStatus =
          fisherman.organization_cooperative === "SI"
            ? "ASSOCIATED"
            : "INDEPENDENT";
        sendData.fishermanOrganization = fishingCard.organization?.abbr_name || "";
        sendData.fishermanExpeditionPLace = fishingCard.lugar?.nombre || "";
        sendData.fishing_arts = fishingCard.fishing_arts;
        sendData.fishermenNationality =
          fishingCard.nacionalidad?.nombre || DEFAULT_VALUE;
        sendData.timeInActivity =
          sendData.fishingCard.tiempo_en_actividad?.nombre_ingles ||
          sendData.fishingCard.time_in_activity;

        switch (fishingCard.tipo_permiso?.codigo) {
          case "UNO":
            sendData.type_of_card = "Pesca Comercial Artesanal";
            sendData.activity = "Artisanal Fishing";
            sendData.type_activity_img = require("@/assets/media/rpg/carnet/artisanal-card.png");
            break;
          case "DOS":
            sendData.type_of_card = "Pesca deportiva";
            sendData.activity = "Sport Fishing";
            sendData.type_activity_img = require("@/assets/media/rpg/carnet/sport-card.png");
            break;
          case "TRES":
            sendData.type_of_card = "Otro";
            sendData.activity = "Other";
            sendData.type_activity_img = require("@/assets/media/rpg/carnet/logo-rgp-carnet.png");
            break;
        }
        switch (fishingCard.location) {
          case "UNO":
            sendData.expedition_place = "SAN ANDRES";
            break;
          case "DOS":
            sendData.expedition_place = "PROVIDENCIA";
            break;
        }
        switch (fishingCard.location) {
          case "UNO":
            sendData.municipio = "SAN ANDRES";
            break;
          case "DOS":
            sendData.municipio = "PROVIDENCIA";
            break;
        }
        this.download = true;
        await this.generarBlobPdf(sendData);
      }
      this.zip.generateAsync({type:'blob'}).then(function(content) {
          saveAs(content, "carnets_pescadores.zip");
      });
    }, 
    async generarBlobPdf(sendData) {
      const doc = new jsPDF("l", "mm", [800, 470], "legal");
      const imgLogoFishingCard = sendData.type_activity_img;
      doc.addImage(imgDataUp, "PNG", 0, 0, 300, 166);
      doc.addImage(this.firmaSecretario, "PNG", 100, 115, 95, 35);
      doc.setProperties({
        title: `${sendData.name} ${sendData.lastName}  - ${sendData.fishin_card_number}`,
      });
      var urlFoto = photoFishermanUrl + sendData.fisherman_photo_file;
      const imgFotoPescador = await this.generarBase64Blob(urlFoto);
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setFontSize(30)
        .setTextColor("#1A237E")
        .text("Fishing Gears".toUpperCase(), 13, 20);

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(
          110,
          20,
          ` ${
            sendData.fishing_arts.map(
              (x) => "" + (x?.pivot?.description || x?.nombre || x)
            ) || DEFAULT_VALUE
          } `
            .trim()
            .toUpperCase()
        );

      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 32, "Landing Site".toUpperCase());
      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 32, `${sendData.landing_zone}`.toUpperCase());
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 45, `STATUS`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 45, `${this.fishermanStatus}`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 58, `ORGANIZATION`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 58, `${this.fishermanOrg}`.toUpperCase());
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 71, `DEDICATION`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 71, `${sendData.timeInActivity}`.toUpperCase());
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setTextColor("#1A237E")
        .setFontSize(30)
        .text(13, 84, `OCCRE TYPE`.toUpperCase());

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(110, 84, `${this.occreType}`.toUpperCase());
      doc.setDrawColor(0);
      doc.setFillColor("#eee");
      doc.roundedRect(0, 90, 300, 25, 3, 3, "F");

      doc
        .setFont("Helvetica")
        .setTextColor("#333")
        .setFontStyle("normal")
        .setFontSize(22)
        .text(
          140,
          100,
          `We Request the Civilian Authorities to provide Cooperation and`,
          null,
          null,
          "center"
        );

      doc
        .setFont("Helvetica")
        .setTextColor("#333")
        .setFontStyle("normal")
        .setFontSize(22)
        .text(
          140,
          110,
          `support required by the carrier during the development of the activity.`,
          null,
          null,
          "center"
        );

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(20)
        .text(
          140,
          151,
          this.$store.state.settings.find(
            (x) => x.nombre == "nombre_secretario"
          ).valor,
          null,
          null,
          "center"
        );

      doc
        .setFont("Helvetica")
        .setFontStyle("normal")
        .setTextColor("#333")
        .setFontSize(22)
        .text(85, 160, `Secretario de Agricultura y Pesca`);

      doc.addPage("l", "mm", "a4");

      doc.addImage(imgData, "PNG", 0, 0, 300, 210);
      doc.addImage(imgLogoGobernacion, "PNG", 160, 12, 110, 25);
      imgLogoFishingCard &&
        doc.addImage(imgLogoFishingCard, "PNG", 63, 12, 90, 25);

      doc.setDrawColor(0);
      doc.setFillColor("#fff");
      doc.rect(0, 42, 93, 123, "F");

      doc.addImage(imgFotoPescador, "PNG", 3, 45, 88, 117);

      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setFontSize(35)
        .setTextColor("#1A237E")
        .text(110, 55, `${(sendData.name || "").toUpperCase()}`);
      doc
        .setFont("Helvetica")
        .setFontStyle("bold")
        .setFontSize(35)
        .setTextColor("#1A237E")
        .text(110, 68, `${(sendData.lastName || "").toUpperCase()}`);

      doc
        .setFont("Helvetica")
        .setFontSize(30)
        .setTextColor("#1A237E")
        .setFontStyle("bold")
        .text(110, 90, `ID NUMBER`);

      doc.setFont("Helvetica").setFontSize(30).text(195, 90, `CARD No.`);

      doc
        .setFont("Helvetica")
        .setFontSize(32)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(
          110,
          103,
          `${new Intl.NumberFormat("es-CO").format(sendData.identification_number)}`
        );

      doc
        .setFont("Helvetica")
        .setFontSize(32)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(195, 103, `${sendData.fishin_card_number}`);
      doc
        .setFont("Helvetica")
        .setFontSize(30)
        .setTextColor("#1A237E")
        .setFontStyle("bold")
        .text(110, 123, `EXPEDITION PLACE `);
      doc
        .setFont("Helvetica")
        .setFontSize(32)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(110, 136, `${sendData.fishermanExpeditionPLace}`);

      doc
        .setFont("Helvetica")
        .setFontSize(22)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(110, 158, `ISSUED: ${sendData.expedition_date || ""}`);

      doc
        .setFont("Helvetica")
        .setFontSize(22)
        .setTextColor(0, 12, 12)
        .setFontStyle("normal")
        .text(195, 158, `EXPIRE: ${sendData.expiration_date}`);
      this.zip.file( `${sendData.name} ${sendData.lastName}  - ${sendData.fishin_card_number}.pdf`, doc.output("blob"));
    },
    reloadFishermen()
    {
      this.filterVigency = Number(this.singleSelect);
      this.name_filter_vigency = 'Vigencia (SI)';
      if (!this.filterVigency) {
        this.name_filter_vigency = 'Vigencia (NO)'
      }
      this.onPaginate({ page: 1, itemsPerPage: 10 });
    },
    verificarPescadores()
    {
      if(this.selected.length > 10)
      {
        let array = this.selected;
        array.pop();
        this.selected = array;

        swal({
          title: "Error!",
          text: "Solo se pueden seleccionar como maximo 10 pescadores",
          icon: "error",
        });
      }
    },
    getQueryFilter() {
      return {
        ...(this.filterLocation && 
          { location:  this.filterLocation }),
        ...(this.filterVigency && { vigency:  this.filterVigency }),
        ...(this.searchFishermen && { search :this.searchFishermen }),
      }
    },
    async filterFishermen(itemsPerPage = 10) {
      const queryString = this.getQueryFilter();
      this.filtrado = await this.getFishermen(this.type, {
        page: 1,
        itemsPerPage,
        query: queryString,
      });
    },
    async generarBase64Blob(urlFoto) {
      this.$store.commit('LOADER', true);
     // this.$store.commit("START_LOADER");
      const { data } = await baseService.get(urlFoto, { responseType: "blob" });
      this.$store.commit('LOADER', false);
      //this.$store.commit("STOP_LOADER");
      return this.blobToBase64(data);
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async generarBase64(urlFoto) {
      return new Promise((resolve) => {
        var base64Img = require("base64-img");
        base64Img.requestBase64(urlFoto, function (err, res, body) {
          resolve(body);
        });
      });
    },

    loadImage(item) {
      this.imageUrl = photoFishermanUrl + item.fisherman_photo_file;
      this.fullName = item.full_name;
      return this.imageUrl, this.fullName;
    },
    closeModal() {
      this.modalVisible = false;
    },
    showModal(item) {
      this.loadImage(item);
      this.modalVisible = true;
    },
    async filterPer(type, value, name_filter) {
      if (type == "muni") {
        this.name_filter_muni = name_filter;
        this.filterLocation = value;
      } else {
        this.name_filter_vigency = name_filter;
        this.filterVigency = value;
        if (this.filterVigency == 0){
          this.singleSelect = false;
        }
      }
      this.filterFishermen();
    },
    permisoVigente(fecha, estado) {
      if (fecha > new Date().toISOString().substr(0, 10) && estado == 1)
        return "green";
      else return "red";
    },
    seeItem: async function (item_id) {
      this.solicitudes = await axios.get(`${getAllRequestByFisherman}/${item_id}`), {
          headers: getHeader(),
        };
      this.solicitudes = this.solicitudes.data;
      await this.$store.dispatch("getFishermen", { _id: item_id });
      this.editedItem = Object.assign({}, this.fishermenData.data);
      this.dialogSee = true;
    },
    Peticion: async function (peticion, item) {
      const verif_req = (
        await axios.get(`${findRequestFishermen}/${item.id}`, {
          headers: getHeader(),
        })
      )?.data;
      if (Object.keys(verif_req).length) {
        this.dialogPro = false;
        this.snackbar = true;
      } else {
        this.$router.push({
          path: `/request-fisherman-${this.type}-${peticion}-${item.id}-new-req`,
        });
      }
    },
    newFisherman() {
      this.$router.push({ path: "/new-fisherman-" + this.type });
    },
    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1);
    },
    viewItem(item) {
      this.$router.push({
        path: `/view-fisherman/${item.identification_number}`,
      });
    },
    ViewPeticiones() {
      this.$router.push({
        path: `/request-fisherman-` + this.type,
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    getNameInSpanish(time_in_activity) {
       const traducciones = {
         "FULL TIME": "TIEMPO COMPLETO",
         "FULL": "TIEMPO COMPLETO",
         "HALF TIME": "MEDIO TIEMPO",
         "OCCASIONAL": "Ocasional",
         "FOR THE WHOLE DAY": "TODO EL DIA",
         "ALL DAY": "TODO EL DIA"
       };
       const nombreIngles = time_in_activity?.nombre_ingles?.toUpperCase();
       return traducciones[nombreIngles] || "Desconocido";
     },
    getNameFishingArts(fishingArts) {
     if (!Array.isArray(fishingArts)) {
       return [];
     }
     return fishingArts.map(art => art.nombre);
    },
    async  base64Image(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        throw new Error("Error al convertir la imagen a Base64: " + error.message);
      }
    },

    async generatePDF(item) {

      const logoUrl = this.shield;
      const logoBase64 = await this.base64Image(logoUrl);

      if (item.location === "UNO") {
        item.expedition_place = "SAN ANDRES";
      } else if (item.location === "DOS") {
        item.expedition_place = "PROVIDENCIA";
      }

      const today = new Date();
      const day = today.getDate();
      const month = today.toLocaleString("es-ES", { month: "long" });
      const year = today.getFullYear();

      const fishing_arts = this.getNameFishingArts(item.fishing_arts);
      const activity = this.getNameInSpanish(item.tiempo_en_actividad);
      const doc = new jsPDF();

      const marginLeft = 15;

      doc.setFontSize(12);
      doc.setFont('Arial');
      doc.text("GOBERNACIÓN", 105, 20, { align: "center" });
      doc.text("Departamento Archipiélago de San Andrés", 105, 25, { align: "center" });
      doc.text("Providencia y Santa Catalina", 105, 30, { align: "center" });
      doc.text("Reserva de Biosfera Seaflower", 105, 35, { align: "center" });
      doc.text("Nit.: 892.400.038 - 2", 105, 40, { align: "center" });

      doc.addImage(logoBase64, "PNG", 80, 45, 50, 50);

      doc.setFontSize(10);
      doc.text(`Certificado No. ${item.fishin_card_number}`, 105, 105, { align: "center" });

      doc.setFontSize(12);
      doc.text("LA SECRETARÍA DE AGRICULTURA Y PESCA", 105, 115, { align: "center" });
      doc.text("CERTIFICA QUE", 105, 120, { align: "center" });

      const cuerpo = `La persona que a continuación se relaciona, presentó documentos requeridos mediante la resolución 002465 de 30 de junio de 2006 en la Secretaría de Agricultura y Pesca, y que se encuentra registrado en el RGP-SAI.`;
      doc.setFontSize(10);
      doc.text(cuerpo, marginLeft, 130, { maxWidth: 180 });

      // Primera tabla
      doc.autoTable({
        startY: 140,
        margin: { left: marginLeft },
        head: [
          [
            "NOMBRES Y APELLIDOS",
            "TIPO DE DOCUMENTO",
            "No. DOCUMENTO IDENTIFICACION",
            "NACIONALIDAD",
            "TIPO DE OCCR",
            "No. OCCR",
          ],
        ],
        body: [
          [
            item.full_name,
            item.id_type,
            item.identification_number,
            item.nacionalidad.nombre.toUpperCase(),
            item.occre,
            item.no_occre,
          ],
        ],
        theme: "grid",
        styles: { fontSize: 8, textColor: [0, 0, 0] },
        headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0] },
        bodyStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      });

      // Segunda tabla
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 5,
        margin: { left: marginLeft },
        head: [["ORGANIZACIÓN", "ESTADO"]],
        body: [[item.organization.name, item.organization_cooperative === "SI" ? "ASOCIADO" : "INDEPENDIENTE"]],
        theme: "grid",
        styles: { fontSize: 8, textColor: [0, 0, 0] },
        headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0] },
        bodyStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      });

      // Tercera tabla
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 5,
        margin: { left: marginLeft },
        head: [["ARTES DE PESCA", "PUERTO DE DESEMBARQUE", "DEDICACION", "VIGENCIA HASTA"]],
        body: [[fishing_arts, item.puerto_desembarque.nombre, activity, item.expiration_date]],
        theme: "grid",
        styles: { fontSize: 8, textColor: [0, 0, 0] },
        headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0] },
        bodyStyles: { fillColor: [240, 240, 240], textColor: [0, 0, 0] },
      });

      doc.setFontSize(10);
      doc.text(
        `La presente se expide a solicitud del interesado, en ${item.expedition_place} a los ${day} días del mes de ${month} del ${year}.`,
        marginLeft,
        doc.lastAutoTable.finalY + 15,
        { maxWidth: 180 }
      );

      doc.setFont('Arial', "normal");
      doc.text(
        `${this.$store.state.settings.find((x) => x.nombre == "nombre_secretario").valor} \n Secretario(a) de Agricultura y Pesca`,
        105,
        doc.lastAutoTable.finalY + 50,
        { align: "center" }
      );

      doc.line(70, doc.lastAutoTable.finalY + 45, 140, doc.lastAutoTable.finalY + 45);

      doc.save(`${item.full_name} certificado.pdf`);
    },

    cardItem(item) {
      this.$store.commit("SET_FISHING_CARD", {
        ...item,
        type_of_card:
          item.type_of_card == "DOS"
            ? item.landing_zone_state
            : item.landing_zone,
      });
    },
    async onPaginate(pagination) {
      let { sortBy, sortDesc } = pagination;
      const queryString = this.getQueryFilter();
      this.filtrado = await this.getFishermen(this.type, {
        ...pagination,
        ...((this.filterVigency || this.filterLocation || sortBy?.[0]) && {
          query: {
            ...queryString,
            ...sortBy?.[0] && {
              [`${sortBy[0]}`]: sortDesc[0]
            }},
        }),
      });
    },
    async getFishermen(type, { page, itemsPerPage, query = null }) {
      const getType = (key) =>
        ({
          art: "UNO",
          ind: "TRES",
          dep: "DOS",
        }[key]);
      await this.$store.dispatch("listFishermen",{
          type:  getType(type),
          params: {
              page,
              itemsPerPage: itemsPerPage < 0 ? this.filtrado.total : itemsPerPage,
              ...(this.organization !== undefined ? {organizationId : this.organization} :{}),
              ...query,
          }
      });
      return this.allFishermen.data;

    },
    async exportFishermens() {
      const queryString = this.getQueryFilter();

      const fishermenData = await this.getFishermen(this.type, {
        page: 1,
        itemsPerPage: this.filtrado.total,
        query: queryString,
      });


      return fishermenData?.data;
    },
    startDownload() {
      this.$store.commit("LOADER", true);
      //this.$store.commit("START_LOADER");
    },
    finishDownload() {
     this.$store.commit("LOADER", false);
      //this.$store.commit("STOP_LOADER");
      this.showAlertExcel = true;
      setTimeout(() => {
        this.showAlertExcel = false;
      }, 1000);
    },
    search() {
      this.$store.commit("LOADER", undefined);
      //this.$store.commit("START_LOADER");
      this.filterFishermen();
    },
  },
};
</script>

<style lang="scss" scoped>
.swal-confirm-text-white {
  color: white ;
}

</style>